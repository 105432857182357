// export const loginMock = {
//   access_token:
//     'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjg2Mjg1MzAyLCJqdGkiOiI1MThlYzU4OTYwZWQ0NzlhODI3OWQ2OWEwNjM4YWYxZiIsInVzZXJfaWQiOjF9.TdlEw4HCmGzuYNxoNki7h0SFepQf9Q-8wRvhfXlRyLQ',
//   expires: '2023-06-03T04:35:02+04:00',
//   user_id: 1,
//   client_id: 2,
// };

// export const getUserMock = {
//   merchant_key: '00',
//   org_type: 'merchant',
//   phone: '',
//   email: 'admin@admin.ru',
//   id: 1,
//   client_id: 2,
//   status: 'ACTIVE',
//   created: '',
//   updated: '',
//   role: '',
// };

// export const getClientsMock = {
//   count: 1,
//   next: null,
//   previous: null,
//   results: [
//     {
//       id: 4,
//       org_type: 'client_individual',
//       status: 'CONTROL',
//       created_date: '2023-05-27T19:29:33',
//       updated_date: '2023-05-29T12:55:37',
//       inn: '',
//       contract_date: '2023-05-27T15:29:33Z',
//       contract_number: '1',
//       client: '1 2 3',
//     },
//   ],
// };

export const getPermissionsMock = [
  {
    id: 1,
    name: 'Клиенты',
    parent_id: null,
    endpoint: '/clients',
    method: null,
  },
  {
    id: 2,
    name: 'Список клиентов',
    parent_id: 1,
    endpoint: '/clients',
    method: 'GET',
  },
  {
    id: 3,
    name: 'Карточка клиента',
    parent_id: 1,
    endpoint: '/clients/{id}',
    method: 'GET',
  },
  {
    id: 4,
    name: 'Получение активности клиента',
    parent_id: 1,
    endpoint: '/clients/{id}/activity',
    method: 'GET',
  },
  {
    id: 5,
    name: 'Редактирование клиента',
    parent_id: 1,
    endpoint: '/clients/{id}',
    method: 'PUT',
  },
  {
    id: 6,
    name: 'Отклонение клиента',
    parent_id: 1,
    endpoint: '/clients/{id}/reject',
    method: 'POST',
  },
  {
    id: 7,
    name: 'Подтверждение клиента',
    parent_id: 1,
    endpoint: '/clients/{id}/confirm',
    method: 'POST',
  },
  {
    id: 8,
    name: 'Создание клиента',
    parent_id: 1,
    endpoint: '/clients/create-instant',
    method: 'POST',
  },
  {
    id: 9,
    name: 'Изменение клиента',
    parent_id: 1,
    endpoint: '/clients/{id}/update-instant',
    method: 'PUT',
  },
  {
    id: 10,
    name: 'Операции',
    parent_id: null,
    endpoint: '/operations',
    method: null,
  },
  {
    id: 11,
    name: 'Список операций',
    parent_id: 10,
    endpoint: '/operations',
    method: 'GET',
  },
  {
    id: 12,
    name: 'Карточка операции',
    parent_id: 10,
    endpoint: '/operations/{id}',
    method: 'GET',
  },
  {
    id: 13,
    name: 'Изменение операции',
    parent_id: 10,
    endpoint: '/operations/{id}',
    method: 'PUT',
  },
  {
    id: 14,
    name: 'Возврат',
    parent_id: 10,
    endpoint: '/operations/{id}/rollback',
    method: 'PUT',
  },
  {
    id: 15,
    name: 'Корректировка',
    parent_id: 10,
    endpoint: '/operations/{id}/corrected',
    method: 'POST',
  },
  {
    id: 16,
    name: 'Метки',
    parent_id: null,
    endpoint: '/labels',
    method: null,
  },
  {
    id: 17,
    name: 'Список меток',
    parent_id: 16,
    endpoint: '/labels',
    method: 'GET',
  },
  {
    id: 18,
    name: 'Карточка метки',
    parent_id: 16,
    endpoint: '/labels/{id}',
    method: 'GET',
  },
  {
    id: 19,
    name: 'Создание меток из файла',
    parent_id: 16,
    endpoint: '/labels/create-labels',
    method: 'POST',
  },
  {
    id: 20,
    name: 'Изменение метки',
    parent_id: 16,
    endpoint: '/labels/{id}',
    method: 'PUT',
  },
  {
    id: 21,
    name: 'История метки',
    parent_id: 16,
    endpoint: '/labels/{id}/history',
    method: 'GET',
  },
  {
    id: 22,
    name: 'История метки по номеру',
    parent_id: 16,
    endpoint: '/labels/labels-history',
    method: 'GET',
  },
  {
    id: 23,
    name: 'Список заявок на выдачу меток',
    parent_id: 16,
    endpoint: '/reserve-labels',
    method: 'GET',
  },
  {
    id: 24,
    name: 'Создание заявки на выдачу меток',
    parent_id: 16,
    endpoint: '/reserve-labels',
    method: 'POST',
  },
  {
    id: 25,
    name: 'Карточка заявки на выдачу меток',
    parent_id: 16,
    endpoint: '/reserve-labels/{id}',
    method: 'GET',
  },
  {
    id: 26,
    name: 'Удаление заявки на выдачу меток',
    parent_id: 16,
    endpoint: '/reserve-labels/{id}',
    method: 'DELETE',
  },
  {
    id: 27,
    name: 'Выдача метки',
    parent_id: 16,
    endpoint: '/reserve-labels/{id}/issue',
    method: 'POST',
  },
  {
    id: 28,
    name: 'Назначение метки машинам из заявки, сформированной в ЛК клиента',
    parent_id: 16,
    endpoint: '/reserve-labels/{id}/set-labels',
    method: 'POST',
  },
  {
    id: 29,
    name: 'Изменение заявки на выдачу меток',
    parent_id: 16,
    endpoint: '/reserve-labels/{id}',
    method: 'PUT',
  },
  {
    id: 30,
    name: 'Отчеты',
    parent_id: null,
    endpoint: '/documents',
    method: null,
  },
  {
    id: 31,
    name: 'Генерация отчета',
    parent_id: 30,
    endpoint: '/documents/generate-report',
    method: 'GET',
  },
  {
    id: 32,
    name: 'Управление',
    parent_id: null,
    endpoint: '/merchants',
    method: null,
  },
  {
    id: 33,
    name: 'Список настроек',
    parent_id: 32,
    endpoint: '/merchants/details',
    method: 'GET',
  },
  {
    id: 34,
    name: 'Изменение настроек',
    parent_id: 32,
    endpoint: '/merchants/details',
    method: 'PUT',
  },
  {
    id: 35,
    name: 'Получение списка тарифов',
    parent_id: 32,
    endpoint: '/tariffs',
    method: 'GET',
  },
  {
    id: 36,
    name: 'Создание нового тарифа',
    parent_id: 32,
    endpoint: '/tariffs',
    method: 'POST',
  },
  {
    id: 37,
    name: 'Карточка тарифа',
    parent_id: 32,
    endpoint: '/tariff/{id}',
    method: 'GET',
  },
  {
    id: 38,
    name: 'Редактирование тарифа',
    parent_id: 32,
    endpoint: '/tariff/{id}',
    method: 'PUT',
  },
  {
    id: 39,
    name: 'Изменение статуса тарифа',
    parent_id: 32,
    endpoint: '/tariff/{id}/update-status',
    method: 'PUT',
  },
  {
    id: 40,
    name: 'Банк',
    parent_id: null,
    endpoint: '/payments',
    method: null,
  },
  {
    id: 41,
    name: 'Список банковских платежей',
    parent_id: 40,
    endpoint: '/payments',
    method: 'GET',
  },
  {
    id: 42,
    name: 'Загрузка банковских платежей в систему',
    parent_id: 40,
    endpoint: '/payments',
    method: 'POST',
  },
  {
    id: 43,
    name: 'Карточка банковского платежа',
    parent_id: 40,
    endpoint: '/payments/{id}',
    method: 'GET',
  },
  {
    id: 44,
    name: 'Редактирование банковского платежа',
    parent_id: 40,
    endpoint: '/payments/{id}',
    method: 'PUT',
  },
  {
    id: 45,
    name: 'Зачисление банковских платежей',
    parent_id: 40,
    endpoint: '/payments/confirm',
    method: 'POST',
  },
  {
    id: 46,
    name: 'АРМ диспетчера',
    parent_id: null,
    endpoint: '/dispatchers',
    method: null,
  },
  {
    id: 47,
    name: 'АРМ инкассации',
    parent_id: null,
    endpoint: '/collectors',
    method: null,
  },
  {
    id: 48,
    name: 'АРМ кассира',
    parent_id: null,
    endpoint: '/cashiers',
    method: null,
  },
];

export const getRolesMock = [];

export const configMenuGetMock = [
  {
    name: 'Пользователи',
    menu_id: 1.0,
  },
  {
    name: 'Операции',
    menu_id: 2.0,
  },
  {
    name: 'Метки',
    menu_id: 3.0,
  },
  {
    name: 'Отчеты',
    menu_id: 4.0,
  },
  {
    name: 'Управление',
    menu_id: 5.0,
  },
  {
    name: 'Банк',
    menu_id: 6.0,
  },
  {
    name: 'АРМ диспетчера',
    menu_id: 7.0,
  },
  {
    name: 'АРМ инкассации',
    menu_id: 8.0,
  },
  {
    name: 'АРМ кассира',
    menu_id: 9.0,
  },
];

export const getReportsMock = [];

export const getReportsMetricsMock = {
  metrics: [
    {
      id: 1,
      display_name: 'Количество транзакций',
      name: 'сount_operations',
    },
    {
      id: 2,
      display_name: 'Сумма транзакций',
      name: 'sum_operations',
    },
    {
      id: 3,
      display_name: 'Количество смен',
      name: 'count_shifts',
    },
  ],
  slices: [
    {
      id: 1,
      display_name: 'Тип оплаты',
      name: 'operation_type',
    },
    {
      id: 2,
      display_name: 'Тарифная группа',
      name: 'vehicle_class',
    },
    {
      id: 3,
      display_name: 'Кассир',
      name: 'cashier',
    },
    {
      id: 4,
      display_name: 'Диспетчер',
      name: 'dispatcher',
    },
    {
      id: 5,
      display_name: 'Подозрительность',
      name: 'is_suspected',
    },
    {
      id: 6,
      display_name: 'Тарифная зона',
      name: 'tariff_zone',
    },
    {
      id: 7,
      display_name: 'Дата',
      name: 'date',
    },
    {
      id: 8,
      display_name: 'ПВП и полосы',
      name: 'lane',
    },
    {
      id: 9,
      display_name: 'Смена',
      name: 'shift_id',
    },
  ],
};

export const getShiftsMock = {
  count: 1,
  next: null,
  previous: null,
  results: [
    {
      id: 1,
      operator_id: 'ac5fb2c6-e43a-48e3-a116-47fc719a69c5',
      day_id: 1,
      lane_id: 1,
      external_id: 'string',
      status: 'CLOSED',
      datetime_start: '2024-07-11T00:00:00',
      datetime_end: '2024-07-12T00:00:00',
      balance_cash: '270.00',
      balance_card: '270.00',
      deposit_cash: '270.00',
      deposit_card: '270.00',
    },
    {
      id: 2,
      operator_id: 'ac5fb2c6-e43a-48e3-a116-47fc719a69c5',
      day_id: 2,
      lane_id: 1,
      external_id: 'string',
      status: 'OPEN',
      datetime_start: '2024-07-10T00:00:00',
      datetime_end: '2024-07-11T00:00:00',
      balance_cash: '140.00',
      balance_card: '1530.00',
      deposit_cash: '270.00',
      deposit_card: '270.00',
    },
  ],
};
