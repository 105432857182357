import { config } from 'app/config';
import store from 'store/store';
import * as common from 'services/common';
import { hmac } from 'services/hmac';
import userService from 'services/userService';
import * as userActions from 'store/actions/userActions';
import { DefaultApi, Configuration } from './rest';
import * as mocks from './mock/mock';

/**
 * Class for work with Swagger generated code
 * TODO: new AbortController, signal = controller.signal, fetch(url, {signal})
 *
 */
class AuthApi {
  searchMethods = ['getBanks'];
  publicMethods = ['loginUser', 'registerUser', 'sendValidationCode', 'validateUserContact', 'refreshTokenUser'];
  mockMethods = ['getShifts', 'getShift', 'getPermissions', 'configMenuGet', 'getMetrics']; //'login', 'getUser', 'getPermissions', 'configMenuGet'];
  //process = false;
  method = '';

  constructor(config) {
    //console.log(config);
    //config.accessToken = this.setAccessToken;
    config.middleware = [{ pre: this.beforeRequest /*, post: this.afterRequest*/ }];
    const apiConfig = new Configuration(config);
    this.api = new DefaultApi(apiConfig);
    //this.api2 = new api._Api(apiConfig);
    this.prepareMethods();
    this.error = null;
  }

  isPublicMethod = (method) => {
    return this.publicMethods.includes(method);
  };

  isSearchMethod = (method) => {
    return this.searchMethods.includes(method);
  };

  isMockMethod = (method) => {
    return this.mockMethods.includes(method);
  };

  beforeRequest = (request) => {
    // check for auth need
    if (!this.isPublicMethod(this.method)) {
      const token = userService.getToken();
      //console.log('with token ' + token)
      // add jwt token to headers
      if (token) {
        request.init.headers['Authorization'] = 'Bearer ' + token;
      }
    }
    //console.log(`--->`, request);
    return request;
  };

  afterRequest = (response) => {
    console.log(`after`, response);
  };

  tryRefreshToken = async (method, rest, api) => {
    let func = 'refreshTokenUser',      
      params = [];

    // if localhost refresh with predefined user
    if (config.localhost) {
      func = 'loginUser';
      params = [
        {
          alias: config.localhostLogin.alias,
          password: hmac(config.localhostLogin.password),
          merchant_key: config.API_KEY,
          recaptcha: '123',
        },
      ];
    }

    let error = false;

    console.log('🚀 ~ tryRefreshToken', func, params);
    const result = await this.apiCall(func, params, api).catch((result) => {
      error = true;
      return result;
    });

    if (error) {
      store.dispatch(userActions.logoutUser());
      return result;
    }

    userService.initUser(result);
    return this.apiCall(method, rest, api);
  };

  /**
   * real api call
   *
   * @param {*} method
   * @param {*} rest
   * @returns
   * @memberof AuthApi
   */
  apiCall(method, params, api) {
    const rest = params ? params.slice(0) : []; //JSON.parse(JSON.stringify(params));
    this.method = method;

    // mock method
    if (/*config.test && */ this.isMockMethod(method)) {
      return Promise.resolve(mocks[method + 'Mock']);
    }

    const promise = rest.length ? this[api][method](...rest) : this[api][method]();

    return promise.catch(async (result) => {
      if (result.status === 404 && this.isSearchMethod(method)) {
        throw result;
      }

      if (!this.isPublicMethod(method)) {
        if (result.status === 401) {
          throw result;
        }
      }

      // convert to json
      let errors = {};
      try {
        errors = await result.json();
      } catch (error) {
        errors = {};
      }

      const detailError = common.isObject(errors) && errors.detail;

      if (result.status === 409 && !detailError) {
        throw errors;
      }

      //console.log('error ' + result.status + ':', errors );
      throw common.getServerErrors(errors, result.status === undefined ? 599 : result.status);
    });
  }

  /**
   * decorate all methods for support auth
   */
  prepareMethods = () => {
    const apis = ['api']; //, 'api2'];
    apis.forEach((api) => {
      Object.getOwnPropertyNames(this[api].__proto__)
        .filter((name) => !['constructor'].includes(name))
        .forEach((name) => {
          // test for 401
          this[name] = async (...rest) => {
            let error = '';
            let result = await this.apiCall(name, rest, api).catch((resultError) => {
              error = resultError;
            });

            // 401
            if (error) {
              if (error.status) {
                if (error.status === 404) {
                  if (!this.isSearchMethod(name)) {
                    //this.props.navigate('/error404');
                  }
                  throw error;
                }
                if (error.status === 401) {
                  result = await this.tryRefreshToken(name, rest, api);
                }
                if (error.status === 409) {
                  throw error;
                }
              } else {
                throw error;
              }
            }
            return result;
          };
        });
    });
  };
}

export default new AuthApi({ basePath: config.networkConfig.apiUrl });
